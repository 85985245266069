import * as React from 'react';
import { emptyAddress, Address } from 'bundles/App/pages/Account/Addresses/types';
import { FormikValues, useFormikContext, FormikHelpers } from 'formik';
import { Label } from 'styleguide/components/forms';
import AddressForm from '../AddressForm/AddressForm';
import { User } from 'app/contexts/UserContextContainer/types';
import HeadingWithAddressSelector from 'app/bundles/App/pages/CheckoutPage/Steps/HeadingWithAddressSelector/HeadingWithAddressSelector';
import UserContext from 'app/contexts/UserContextContainer/UserContext';
import FormikFieldWrapper from '../FormikFieldWrapper/FormikFieldWrapper';

export const updateAddress = <ValuesType,>(
  address: Address,
  prefix: string,
  setFieldValue: FormikHelpers<ValuesType>['setFieldValue'],
) => {
  setFieldValue(`${prefix}.name`, address.name);
  setFieldValue(`${prefix}.company`, address.company);
  setFieldValue(`${prefix}.address1`, address.address1);
  setFieldValue(`${prefix}.address2`, address.address2);
  setFieldValue(`${prefix}.city`, address.city);
  setFieldValue(`${prefix}.stateId`, address.stateId);
  setFieldValue(`${prefix}.countryId`, address.countryId);
  setFieldValue(`${prefix}.zipcode`, address.zipcode);
  setFieldValue(`${prefix}.phone`, address.phone);
  setFieldValue(`${prefix}.verified`, address.verified);
};

export const onAddressSelect = <ValuesType,>(
  address: Address,
  prefix: string,
  setFieldValue: FormikHelpers<ValuesType>['setFieldValue'],
) => {
  updateAddress(address, prefix, setFieldValue);
};

export type AddressTypeVars = {
  addressId: string;
  prefix: string;
  title: string;
  saveNewAddress: string;
};

export const setEmptyAddress = <ValuesType,>(
  addressTypeVars: Pick<AddressTypeVars, 'prefix' | 'addressId'>,
  setFieldValue: FormikHelpers<ValuesType>['setFieldValue'],
  zip = null,
) => {
  updateAddress(emptyAddress, addressTypeVars.prefix, setFieldValue);
  setFieldValue(addressTypeVars.addressId, null);
  if (zip) {
    setFieldValue(`${addressTypeVars.prefix}.zipcode`, zip);
  }
};

export const getAddressTypeVars = (addressType: 'bill' | 'ship' | ''): AddressTypeVars => {
  const addressTypeVars: AddressTypeVars = {
    title: '',
    addressId: 'addressId',
    prefix: 'addressAttributes',
    saveNewAddress: 'saveNewAddress',
  };
  if (addressType === 'bill') {
    addressTypeVars.title = 'Billing Address';
    addressTypeVars.addressId = 'billAddressId';
    addressTypeVars.prefix = 'billAddressAttributes';
    addressTypeVars.saveNewAddress = 'saveNewBillingAddress';
  } else if (addressType === 'ship') {
    addressTypeVars.title = 'Shipping Address';
    addressTypeVars.addressId = 'shipAddressId';
    addressTypeVars.prefix = 'shipAddressAttributes';
    addressTypeVars.saveNewAddress = 'saveNewShippingAddress';
  }
  return addressTypeVars;
};

interface Props {
  addressType: 'ship' | 'bill' | '';
  newAddress: boolean;
  setNewAddress: (newAddress: boolean) => void;
  heading?: string;
  addressFormProps?: Partial<React.ComponentProps<typeof AddressForm>>;
  addresses?: Address[];
  address?: Address;
  selectedUser?: User;
  headingWithAddressSelectorProps?: Partial<React.ComponentProps<typeof HeadingWithAddressSelector>>;
  titleOnTheLeft?: boolean;
  addNewAddressToBookCheckBox?: boolean;
  addBottomBorder?: boolean;
}

const AddressBook = ({
  address,
  addresses,
  newAddress,
  setNewAddress,
  heading,
  addressFormProps,
  addressType,
  selectedUser,
  headingWithAddressSelectorProps,
  titleOnTheLeft = false,
  addNewAddressToBookCheckBox = false,
  addBottomBorder = true,
}: Props) => {
  const { currentUser } = React.useContext(UserContext) ?? { currentUser: selectedUser || null };
  const formikProps = useFormikContext<FormikValues>();
  const addressTypeVars = getAddressTypeVars(addressType);
  const addressesToFilterOn = selectedUser?.addresses || addresses || [];
  return (
    <>
      <AddressForm
        address={
          address || addressesToFilterOn.find(adr => adr.id === formikProps.values[addressTypeVars.addressId])
        }
        newAddress={newAddress}
        setNewAddress={() => {
          setNewAddress(true);
          setEmptyAddress<FormikValues>(addressTypeVars, formikProps.setFieldValue);
        }}
        prefix={addressTypeVars.prefix}
        zipWarning={false}
        addressFormTitle={
          currentUser && (
            <HeadingWithAddressSelector<FormikValues>
              heading={heading ?? addressTypeVars.title}
              addresses={addressesToFilterOn}
              selectedAddressId={formikProps.values[addressTypeVars.addressId]}
              onSelectAddress={(addressId: number) => {
                if (addressId) {
                  onAddressSelect<FormikValues>(
                    addressesToFilterOn.find(adr => adr.id === addressId),
                    addressTypeVars.prefix,
                    formikProps.setFieldValue,
                  );
                  setNewAddress(false);
                } else {
                  onAddressSelect<FormikValues>(
                    emptyAddress,
                    addressTypeVars.prefix,
                    formikProps.setFieldValue,
                  );
                  setNewAddress(true);
                }
              }}
              fieldAddressId={addressTypeVars.addressId}
              formikProps={formikProps}
              titleOnTheLeft={titleOnTheLeft}
              {...headingWithAddressSelectorProps}
            />
          )
        }
        {...addressFormProps}
      />
      {newAddress && !!addNewAddressToBookCheckBox && (
        <div
          className={`mb-6 flex items-center justify-start ${
            addBottomBorder ? 'border-b border-gray-50 pb-6' : ''
          }`}
        >
          <FormikFieldWrapper
            name={addressTypeVars.saveNewAddress}
            size="sm"
            data-cy="addAddressToAddressBookCheckbox"
            componentType="checkbox"
            labelComponent={
              <Label
                asSpan
                placement="right"
                normalFontWeight
                className="!font-hvRoman !text-sm text-gray-500"
              >
                Add new address to my address book
              </Label>
            }
          />
        </div>
      )}
    </>
  );
};

export default AddressBook;
